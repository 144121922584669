/* Default styles */
.responsive-heading-h2 {
    color: #956B35;
    font-weight: bold;
  
  }
  
  .responsive-heading-h1 {
    color: #956B35;
    margin-top: 0;
   
  }
  
  /* For tablets and small desktops */
  @media (max-width: 1024px) {
    .responsive-heading-h2 {
      font-size: 2rem;
    }
  
    .responsive-heading-h1 {
      font-size: 3.5rem;
    }
  }
  
  /* For tablet devices */
  @media (max-width: 768px) {
    .responsive-heading-h2 {
      font-size: 1.75rem;
    }
  
    .responsive-heading-h1 {
      font-size: 3rem;
    }
  }
  
  /* For small mobile devices */
  @media (max-width: 480px) {
    .responsive-heading-h2 {
      font-size: 2rem;
    }
  
    .responsive-heading-h1 {
      font-size: 1.8rem;
    }
  }
  


  .responsive-container {
    margin-left: 3rem;
  
    @media (max-width: 1200px) {
      margin-left: 2rem;  // Adjust for large screens (e.g., tablets)
    }
  
    @media (max-width: 768px) {
      margin-left: 1rem;  // Adjust for medium screens (e.g., portrait tablets)
    }
  
    @media (max-width: 480px) {
      padding-left: 1.5rem;  // Adjust for small screens (e.g., phones)
      margin-left: 0rem;
    }
  }

  




  .responsive-paragraph {
    margin-top: 2rem;
    width: 85vh;
  
    @media (max-width: 1200px) {
      width: 70vh;  // Adjust for large screens (e.g., tablets)
    }
  
    @media (max-width: 768px) {
      width: 60vh;  // Adjust for medium screens (e.g., portrait tablets)
    }
  
    @media (max-width: 480px) {
      margin-top: 1rem;
      width: 40vh;  // Adjust for small screens (e.g., phones)
    }
  }
  

  .responsive-image {
    display: block; /* Default display */
  
  

    @media (max-width: 480px) {
      display: none; /* Hide the image on screens 768px and smaller */

    }
  }
  

  .responsive-grid {
    margin-left: 0rem !important; /* Default margin */
  
    @media (max-width: 480px) {
      margin-left: 0rem !important; /* Apply negative margin on mobile */
    }
  }
  


//   .icon-grid {
  

//   img {
//     /* Default icon size */
//     height: 133px;
//     width: auto;
//   }

//   @media (max-width: 480px) {
  
//     img {
//       height: 180px; /* Increase the size of the icons on mobile */
//       width: 180px;
//     }
//   }
// }


.custom-list {


  @media (max-width: 480px) {
    font-size: 0.7rem; /* Increase font size on mobile */
    line-height: 1.5; /* Increase line height on mobile */
    width: 9rem;
    padding-left: 15px; /* Increase padding on mobile */
    font-weight: bold;
  }
}


.image-button {
  border: none;
  background: transparent;
  margin-bottom: -3px;
  img {
    width: 500px; // Default size
    transition: width 0.3s; // Smooth transition for resizing
    
    // Mobile styles
    @media (max-width: 480px) {
      width: 350px; // Size for mobile screens
    }
  }
}


/* Default to center alignment for mobile */

.text-containerC {
  justify-content: right;
  align-items: flex-end;
  display: flex;
}

.image-containerC {
  justify-content: left;
  align-items: flex-end;
  display: flex;
}




@media (max-width: 480px) {
  .image-containerC {
    justify-content: center; /* Align left on desktop */
    align-items: flex-end;
    display: flex;
  }

  .text-containerC {
    // justify-content: center; /* Align right on desktop */
    // align-items: flex-end;
    justify-content: center;
    display: flex;
    text-align: center;
  }

}


html {
  scroll-behavior: smooth;
}


/* SCSS class for mobile-only display */
.mobile-only {
  display: none; // Default hidden

  // Show the image on devices with a screen width of 768px or less
  @media screen and (max-width: 768px) {
    display: block;
  }
}

@media only screen and (max-width: 480px) {
  .desktopOnly {
    display: none;
  }
}


@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}



@media only screen and (min-width: 768px) {
  .mobileOnly {
    display: none;
  }
}



// @media only screen and (max-width: 480px) {
//   #history_section {
//     padding: 10px; /* Add padding for better spacing on small screens */
//   }

//   svg {
//     width: 100%; /* Scale SVG to fit smaller screens */
//     height: auto; /* Keep aspect ratio */
//   }

//   g text {
//     font-size: 12px; /* Decrease font size for mobile */
//   }

//   .test div {
//     flex-direction: column; /* Stack text on top of each other */
//   }
// }




@media (max-width: 480px) {
  .mobileTest2 {
    transform: rotate(60deg);
    height: 200px;
    margin-bottom: 5rem;
    margin-top: 10rem;
  }
  .textMobile2 {
    transform: rotate(220deg);
    font-size: 16px;

  }
  .imageMobile2 {
    width: 36px;
    height: 36px;
  }
}


.history-content {
  width: 100%;
  margin-top: 20px;
  animation: fadeIn 1s forwards;
}

.history-text {
  width: 100%;
  margin-bottom: 20px;
  padding: 0 20px;
  box-sizing: border-box;
  color: #8F734F;

}

.history-title {
  font-weight: bold;
  margin-bottom: 10px;
}

@media (max-width: 480px) {
  .history-text {
    padding: 0 15px;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}


.history-section {
  width: 100%;
  max-width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.history-content {
  width: 100%;
  margin-top: 20px;
  animation: fadeIn 1s forwards;
  color: #8F734F;

}


.title-container {
  width: 100%;
  max-width: 1200px; /* Adjust this value as needed */
  padding: 0 20px;
  box-sizing: border-box;
  align-self: flex-start;
}



.documentation-section {
  width: 100%;
  // background-color: #DCD4CD;
  height: 100%;
  padding: 20px;
}

.grid-item {
  display: flex;
  justify-content: center;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.icon-container {
  margin-bottom: 20px;
}

.item-title {
  margin: 10px 0;
  font-weight: bold;
  text-align: center;
}

.links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.links-container a {
  margin: 5px 0;
  text-decoration: none;
  color: #8F734F;
  text-align: center;
  width: 100%;
}

.links-container a:hover {
  text-decoration: underline;
}

@media (max-width: 960px) {
  .content-wrapper {
    padding: 10px;
  }
}

// #gallery_section > div:first-child {
//   height: 300px;
// }

@media (max-width: 480px) {
  #gallery_section > div:first-child {
    height: 450px;
  }
}